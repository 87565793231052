import { ApolloClient } from '@apollo/client'
import { ApolloContext } from '@/services/types/Gql'
import getPhonesForAreaCode from '@/graphql/phone-area-codes/getPhonesForAreaCode.gql'
import { AvailableNumber } from '~/services/types/PhoneAreaCode'

interface IPhoneAreaCodeGql {
  $apollo: ApolloClient<any>
  getPhoneNumbersByAreaCode: (tenantId: string, threadId: string) => Promise<Array<AvailableNumber>>
}

export default class PhoneAreaCodeGql implements IPhoneAreaCodeGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  getPhoneNumbersByAreaCode (areaCode: string, tenantId: string) {
    return this.$apollo.query({
      query: getPhonesForAreaCode,
      fetchPolicy: 'no-cache',
      variables: {
        areaCode,
        tenantId
      }
    }).then(result => result?.data.phoneNumberAvailability.availableNumbers) as Promise<Array<AvailableNumber>>
  }
}
